import React, { useState, useEffect } from 'react';
import { Route, Link } from 'react-router-dom';
import HorizontalScroll from '@oberon-amsterdam/horizontal';
import Home from '../home';
import About from '../about';
import Story0 from '../story/story-0';
import Story1 from '../story/story-1';
import Story2 from '../story/story-2';
import Story3 from '../story/story-3';
import LogoBlack from '../../assets/antimatter-logo-simple.svg';
import LogoWhite from '../../assets/antimatter-logo-white.svg';
import LogoTicknovate from '../../assets/logo-ticknovate-mono.png';
import LogoRemarkAI from '../../assets/logo-remarkai-mono.png';
import LogoJellyfish from '../../assets/logo-jellyfish.svg';
import LogoVerizonMedia from '../../assets/logo-verizon-media.png';
import LogoBobtrade from '../../assets/logo-bobtrade.png';
import LogoCloudIQ from '../../assets/logo-cloud-iq.png';
import LogoTheSquare from '../../assets/logo-the-square.png';
import LogoHearstUK from '../../assets/logo-hearst-mono.png';
import S from './index.styles';

if (window.innerWidth > 1025) {
  new HorizontalScroll();
}

const App = () => {
  const [darkLogoOpacity, setDarkLogoOpacity] = useState(1);
  const [hasPassedTransition, setHasPassedTransition] = useState(false);
  const [isBeginning, setIsBeginning] = useState(true);

  useEffect(() => {
    const transitionBlock = document.getElementById('transition-1');
    window.onscroll = () => {
      const block = transitionBlock.getBoundingClientRect();

      if (window.scrollX === 0) {
        setIsBeginning(true);
      } else {
        setIsBeginning(false);
      }

      if (block.left > window.innerWidth) {
        setHasPassedTransition(false);
      } else {
        setHasPassedTransition(true);
      }

      // Change opacity while scrolling over transition block
      if (block.left < 0 && block.right > 0) {
        const percentTransition = (block.width - block.right) / block.width;
        setDarkLogoOpacity(1 - percentTransition);
      }
      // If transition block is passed and out of viewport
      else if (block.right < 0 && darkLogoOpacity > 0) {
        setDarkLogoOpacity(0);
      }
      // If block is to the right of viewport
      else {
        setDarkLogoOpacity(1);
      }
    };
  }, []);

  return (
    <S.Container id="home">
      <header>
        <S.LogoContainer>
          <S.Logo src={LogoBlack} style={{ opacity: `${darkLogoOpacity}` }} />
          <S.Logo
            src={LogoWhite}
            style={{ opacity: `${1 - darkLogoOpacity}` }}
          />
          <S.ScrollButton
            isBeginning={isBeginning}
            onClick={() => {
              document.getElementById('transition-1').scrollIntoView();
              new HorizontalScroll();
            }}>
            scroll -->
          </S.ScrollButton>
          <S.BackButton
            hasPassedTransition={hasPassedTransition}
            onClick={() => {
              document.getElementById('home').scrollIntoView();
              new HorizontalScroll();
            }}>
            back
          </S.BackButton>
        </S.LogoContainer>
      </header>

      <main>
        <S.BlockContainer>
          <S.Block>
            <Story0 prevColour="white" nextColour="white" color="black">
              <h1>To dream</h1>
            </Story0>
          </S.Block>
          <S.Block>
            <Story1 prevColour="white" nextColour="red">
              <h1>To create</h1>
            </Story1>
          </S.Block>
          <S.Block>
            {/* Neon carrot */}
            <Story2 prevColour="red" nextColour="#FF9933">
              <h1>To inspire</h1>
            </Story2>
          </S.Block>
          <S.Block>
            <Story2 prevColour="#FF9933" nextColour="#50BFE6">
              <h1>To innovate</h1>
            </Story2>
          </S.Block>
          <S.Block>
            {/* <Story3 prevColour="#50BFE6" nextColour="#00ff0d"> */}
            <Story3 prevColour="#50BFE6" nextColour="#ff00f2">
              <h1 style={{ textTransform: 'capitalize' }}>Fullstack web app development</h1>
              <h1 style={{ marginTop: '20px', fontStyle: 'italic' }}>Previous clients</h1>
              <S.PortfolioContainer>
                <a href="https://www.ticknovate.com/" target="_blank">
                  <img src={LogoTicknovate} />
                </a>
                <a href="https://remarkvision.com/" target="_blank">
                  <img src={LogoRemarkAI} />
                </a>
                <a href="https://www.jellyfish.com/" target="_blank">
                  <img src={LogoJellyfish} />
                </a>
                <a href="https://www.verizonmedia.com/" target="_blank">
                  <img src={LogoVerizonMedia} />
                </a>
                <a href="https://www.cloudiq.com/" target="_blank">
                  <img src={LogoCloudIQ} />
                </a>
                <a href="https://www.crunchbase.com/organization/bobtrade/" target="_blank">
                  <img src={LogoBobtrade} />
                </a>
                <a href="https://www.thesquareapp.com/" target="_blank">
                  <img src={LogoTheSquare} />
                </a>
                <a href="https://www.hearst.co.uk/" target="_blank">
                  <img src={LogoHearstUK} />
                </a>
              </S.PortfolioContainer>
            </Story3>
          </S.Block>
          {/* purple (opposes green) */}
          {/* <S.Block>
            <Story3 prevColour="#00ff0d" nextColour="#ff00f2">
            <Story3 prevColour="#00ff0d" nextColour="#ff00f2">
              <h1>Fullstack web app development</h1>
            </Story3>
          </S.Block> */}
          <S.Block>
            {/* <Story3 prevColour="#ff00f2" nextColour="navy">
              <h1>Ambition</h1>
            </Story3> */}
            <Story3 prevColour="#ff00f2" nextColour="navy">
              <h1>Let's get building</h1>
              <p>
                <a
                  href="mailto:nicholas@antimatterhq.com"
                  style={{
                    position: 'relative',
                    color: 'red',
                    textDecoration: 'none',
                    fontWeight: 'bold'
                  }}>
                  nicholas@antimatterhq.com
                </a>
              </p>
            </Story3>
          </S.Block>
          {/* <S.Block>
            <Story3 prevColour="navy" nextColour="black">
              <h1>Let's get building</h1>
              <p>
                <a
                  href="mailto:nicholas@antimatterhq.com"
                  style={{
                    position: 'relative',
                    color: 'red',
                    textDecoration: 'none',
                    fontWeight: 'bold'
                  }}>
                  nicholas@antimatterhq.com
                </a>
              </p>
            </Story3>
          </S.Block> */}
        </S.BlockContainer>
      </main>
    </S.Container>
  );
};

export default App;
