import styled from 'styled-components';

const Container = styled.div`
  margin: 20px;
  @media (max-width: 1025px) {
    margin: 0 0 0 10px;
  }
`;

const Logo = styled.img`
  position: fixed;
  z-index: 10;
  height: 100px;
  width: 200px;
`;

const LogoContainer = styled.div``;

const BlockContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const Block = styled.div`
  height: 100%;
  flex-basis: 150%;
  flex-grow: 0;
  flex-shrink: 0;
`;

const PortfolioContainer = styled.div`
  display: block;
  padding: 0 20px;
  margin: auto;

  img {
    cursor: pointer;
    max-width: 200px;
    transition: filter 0.5s;
    margin-right: 30px;

    @media (max-width: 1025px) {
      margin-top: 20px;
    }
  }
  img:hover {
    filter: invert(100%);
  }
`;

const BackButton = styled.span`
  z-index: 10;
  color: white;
  position: fixed;
  top: 90%;
  right: 5%;
  display: ${props => (props.hasPassedTransition ? 'block' : 'none')};
  cursor: pointer;
`;

const ScrollButton = styled.span`
  z-index: 4;
  color: black;
  position: fixed;
  top: 90%;
  right: 5%;
  transition: opacity 1s;
  transitiondelay: 0.5s;
  opacity: ${props => (props.isBeginning ? '1' : '0')};
  cursor: pointer;
`;

const S = {
  Container,
  Logo,
  LogoContainer,
  BlockContainer,
  Block,
  PortfolioContainer,
  BackButton,
  ScrollButton
};

export default S;
