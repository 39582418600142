import styled from 'styled-components';

const StoryContainer = styled.div`
  display: flex;
  flex-direction: row;
  position: absolute;
  top: 0;
  height: 100%;
  width: 150%;
  z-index: ${props => props.zIndex || 5};
`;

const ColouredBlock = styled.div`
  text-align: center;
  background: ${props => props.nextColour};
  color: ${props => props.color || 'white'};
  width: 150%;
  & > h1 {
    margin-top: 13%;
  }
  @media (max-width: 1024px) {
    h1 {
      margin-top: 20%;
    }
  }
`;

const TransitionBlock = styled.div`
  display: block;
  width: 10%;
  background: ${props => props.nextColour}; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to bottom right,
    ${props => props.prevColour} 49%,
    ${props => props.nextColour} 50%
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to bottom right,
    ${props => props.prevColour} 49%,
    ${props => props.nextColour} 50%
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
`;

export default { StoryContainer, TransitionBlock, ColouredBlock };
