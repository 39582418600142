import React from 'react';
import S from './story.styles';

const Story3 = ({
  prevColour = 'white',
  nextColour = 'red',
  color,
  children
}) => {
  return (
    <S.StoryContainer>
      <S.TransitionBlock prevColour={prevColour} nextColour={nextColour} />
      <S.ColouredBlock nextColour={nextColour} color={color}>
        {children}
      </S.ColouredBlock>
    </S.StoryContainer>
  );
};

export default Story3;
